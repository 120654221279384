import React from 'react';
import { Carousel } from 'antd';
// import carousel01 from "./images/carousel/boy.jpg"
// import carousel02 from "../company_images/images/carousel/covid.jpg"
// import carousel03 from "../company_images/images/carousel/shopping.jpg"
import carousel04 from "./company_images/images/carousel/carousel01.jpg"

// Import the PNG image file

const contentStyle = {
  height: '800px',
  width: "100%",
  textAlign: 'center',
  objectFit: 'cover'
};

const App = () => (
  <Carousel>
    
    <div>

      <img src={carousel04} alt="Image 1" style={contentStyle} />
    </div>
    {/* <div>
      <h3 style={contentStyle}>
      <img src={carousel02} alt="Image 1" style={contentStyle} /> 
      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>
      <img src={carousel03} alt="Image 1" style={contentStyle} /> 

      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>
      <img src={carousel04} alt="Image 1" style={contentStyle} /> 

      </h3> */}
    {/* </div> */}
  </Carousel>
);

export default App;
