import './App.css';
import OttoAckerman from './components/OttoAckerman'
import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={
        
          <OttoAckerman />
   
        } />
        <Route path="/buffalo_boulevard" />
 </Routes>
 </BrowserRouter> 
  );
}

export default App;
