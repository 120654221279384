import React from 'react';
import styles from './OttoAckermanFooter.module.css';
import x from './company_images/images/x.png';
import insta from './company_images/images/insta.png';
import fb from './company_images/images/fb.png';
import { Col, Row } from 'antd';
import ottoackermanLogo from './company_images/images/ottowhite.png'

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContainer}>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={24} md={8}> 
          <div className={styles.brand}>
            <img src={ottoackermanLogo} alt="Otto Ackerman logo" className={styles.logoStyle} />
            <p className={styles.brandaddress}>203, Barley lane Chadwell Heath Romford</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <div className={styles.footericon}>
            <p className={styles.iconheading}>Follow us on</p>
            <div className={styles.icons}>
              <a href="https://www.facebook.com/profile.php?id=61560296017629" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="Facebook" className={styles.footerimage} />
              </a>
              <a href="https://x.com/Ottoackerman" target="_blank" rel="noopener noreferrer">
                <img src={x} alt="X" className={styles.footerimage} />
              </a>
              <a href="https://www.instagram.com/otto_ackerman_london?igsh=N2VsMGx0bjJidWI5&utm_source=qr" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="Instagram" className={styles.footerinsta} />
              </a>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <p className={styles.contact}> 
            <a href='mailto:contact@ottoackerman.com' className={styles.contactLink}>contact@ottoackerman.com</a>
          </p>
        </Col>
      </Row>
    </div>
  </footer>
);

export default Footer;