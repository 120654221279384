import React from 'react';
import { Link } from 'react-router-dom';

import OttoAckermanCarousel from './OttoAckermanCarousel';
import OttoAckermanFooter from './OttoAckermanFooter';
import OttoAckermanNavBar from './OttoAckermanNavBar'
import OttoAckermanBrandCards from './OttoAckermanBrandcards';
import { text } from '@fortawesome/fontawesome-svg-core';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';


const productStyle = {
  textAlign: 'center',
  fontFamily: 'Arial, san-serif',
  fontSize: 'x-large',
  marginTop: '20px',
  color: '#242424',
  fontWeight: 'bolder'
};


const OttoAckerman = () => {
  return (
    <div>
      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
        <OttoAckermanNavBar />
      </div>
      <div style={{ paddingTop: '60px' }}>
        <OttoAckermanCarousel />
        <h1 style={productStyle}>Products</h1>
        <OttoAckermanBrandCards />
        <OttoAckermanFooter />
      </div>
    </div>
  );
};

export default OttoAckerman;
