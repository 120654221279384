import React, { useState, useEffect } from 'react';

import styles from './OttoAckermanBrandcards.module.css'
import { useNavigate } from 'react-router-dom';

const textStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  paddingLeft: '20px'
}

const priceStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  paddingLeft: '10px',
  marginBottom: '10px',
  color: '#7D7D7D'
}

const fitStyle = {
  fontFamily: 'Arial, sans-serif',
  fontSize: '15px',
  paddingLeft: '10px',
  color: '#7D7D7D',
  fontWeight: 'bolder'
}

const Card = ({ id, name, price, image, path, fit }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };
  const imageUrl = image.length > 0 ? image[0].image : ''; 

  return (
    <div className={styles.card}>
      <img src={imageUrl} alt={name} />
      <h2 className={styles.cardname}>{name}</h2>
      <p style={fitStyle}> {fit}</p>  
      <p style={priceStyle}>£ {price}</p>  

    </div>
  );
};

const CardRow = ({ data }) => (
  <div className={styles.cardrow} style={textStyle}>
    {data.map((item) => (
      <Card
        key={item.id}
        id={item.id}
        name={item.name}
        price={item.price}
        image={item.image}
        fit={item.fit}
        path={`/brand/${item.id}`}
      />
    ))}
  </div>
);

export default function BrandCards() {
  const [brandData, setBrandData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.worldofecstacy.com/companies/list-products/?company=1');
        const data = await response.json();
        setBrandData(data.results); // Assuming API response contains 'results' key
      } catch (error) {
        console.error('Error fetching brand data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="card-container">

      <CardRow data={brandData} />
    </div>
  );
}
