
import React from 'react';
import styles from './OttoAckermanNavbar.module.css';
import ottoackermanLogo from './company_images/images/ottoackermancaps.png'



const OttoAckermanNavbar = () => {
    return (
      <nav className={styles.navbar}>
        <div className={styles.container}>
        <img src={ottoackermanLogo} alt="Description of the image" 
        className={styles.logoStyle} />
        </div>
      </nav>
    );
  };
  
export default OttoAckermanNavbar;